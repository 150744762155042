.recaptcha-links {
    grid-column-start: 3;
    grid-column-end: 24;
    padding: 0;
    list-style-type: none;
    margin: var(--spacing-l) 0 0;

}

@media (min-width: 768px) {
    .recaptcha-links {
        grid-column-start: 5;
        grid-column-end: 14;
        margin: 0;
    }
}

/*.recaptcha-title {*/
/*    color: var(--color-text-secondary);*/
/*    font-size: var(--font-link);*/
/*    position: relative;*/
/*}*/

.recaptcha-title {
    font-family: var(--bodyFont);
    font-size: var(--font-link);
    margin: 0 0 calc(var(--spacing-xs) - 10px);
    letter-spacing: -.25px;
    font-weight: 600;
    padding: 0;
}

@media (min-width: 768px) {
    .recaptcha-title {
        letter-spacing: 0;
    }
}

.links {
    grid-column-start: 3;
    grid-column-end: 9;
    list-style-type: none;
    margin: var(--spacing-l) 0 0;
    padding: 0;
}

@media (min-width: 768px) {
    .links {
        grid-column-start: 14;
        grid-column-end: 17;
        margin: 0;
    }
}

.resume {
    grid-column-start: 9;
    grid-column-end: 15;
    list-style-type: none;
    margin: var(--spacing-l) 0 0;
    padding: 0;
}

@media (min-width: 768px) {
    .resume {
        grid-column-start: 17;
        grid-column-end: 20;
        margin: 0;
    }
}

.inspire {
    grid-column-start: 15;
    grid-column-end: 21;
    list-style-type: none;
    margin: var(--spacing-l) 0 0;
    padding: 0;
}

@media (min-width: 768px) {
    .inspire {
        grid-column-start: 20;
        grid-column-end: 23;
        margin: 0;
    }
}

.link-title {
    font-family: var(--bodyFont);
    font-size: var(--font-link);
    margin: 0 0 calc(var(--spacing-xs) - 10px);
    letter-spacing: -.25px;
    font-weight: 600;
    padding: 0;
}

@media (min-width: 768px) {
    .link-title {
        letter-spacing: 0;
    }
}

.link-li {
    margin: 0;
    padding: 0;
    font-size: var(--font-link);
}

.link {
    background: none;
    color: var(--color-text-secondary);
    text-decoration: none;
    display: block;
    padding: calc(var(--16px) - 6px) 0;
    margin: 0;
    border: 0;
    transition: color .2s ease-in-out;
    border-radius: calc(var(--20px) - 10px);
    transition: all .2s ease-in-out;
    cursor: pointer;
}
