.experience-holder {
    padding: 0 calc(var(--24px) + 6px) var(--spacing-s);
}

.experience {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: var(--24px);
    margin: var(--spacing-l) auto 0;
    max-width: 900px;
}

.experience-block {
    border-radius: var(--radius-base);
    background: var(--color-white30);
    backdrop-filter: blur(10px) saturate(1.3);
    -webkit-backdrop-filter: blur(10px) saturate(1.3);
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    transition: all .2s var(--transition-ease);
}

.experience-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--24px);
    position: relative;
    gap: var(--14px);
    height: 100%;
}

@media (min-width: 768px) {
    .experience-inner {
        padding: var(--42px);
    }
}

.experience-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: calc(var(--16px) - 8px);
    transition: all .2s var(--transition-ease);
}

.experience-title-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: var(--color-white30);
    border-radius: var(--radius-base);
}

.experience-title {
    font-size: var(--font-h4);
    font-weight: 700;
    color: var(--color-text-primary);
    font-family: var(--titleFont);
    margin: 3px 0 3px 8px;
}

.experience-icon {
    height: 32px;
    width: 32px;
    margin: 3px 8px 3px 0;
    color: var(--color-text-primary);
    transition: all .2s var(--transition-ease);
}

.experience-icon:hover {
    color: var(--color-green450);
}

.experience-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: var(--16px);
    color: var(--color-text-primary);
    font-weight: 600;
    font-family: var(--bodyFont);
}

@media (min-width: 768px) {
    .experience-info {
        font-size: var(--font-link);
    }
}

.experience-company {}

.experience-date {}

.experience-description {
    font-family: var(--bodyFont);
    font-size: var(--16px);
    line-height: 160%;
    color: var(--color-text-secondary);
    margin-top: 5px;
}

@media (min-width: 768px) {
    .experience-description {
        font-size: var(--font-link);
    }
}

.experience-item {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .experience-item {
        margin-bottom: 16px;
    }
}

.resume-skills {
    margin-top: var(--spacing-l);
    padding: calc(var(--64px)* 1.5) calc(var(--24px) + 6px) calc(var(--64px)* 2);
}

.skills-content {
    display: grid;
    max-width: var(--max-width);
    margin: 0 auto;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: var(--64px);
}

@media (min-width: 992px) {
    .skills-content {
        grid-row-gap: calc(var(--64px)* 2);
    }
}

@media (min-width: 768px) {
    .skills-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

.skills-1, .skills-2 {
    position: relative;
    transition: all .2s var(--transition-ease);
    width: 100%;
}

.skill-wrap {
    padding: 0 var(--14px);
    display: flex;
}


.skill-image-wrap {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    background: var(--color-white30);
    transition: all .2s var(--transition-ease);
    justify-content: center;
    align-items: center;
}

.skill-image {
    object-fit: contain;
    position: relative;
    z-index: var(--zindex-content);
    width: 60px;
    height: 60px;
    margin: 3px;
}

.skill-info-wrap {
    padding: var(--14px) 0 var(--14px) var(--14px);
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: calc(var(--16px) - 8px);
}

.skill-title {
    font-family: var(--bodyFont);
    font-size: var(--16px);
    color: var(--color-text-primary);
    font-weight: 600;
}

@media (min-width: 768px) {
    .skill-title {
        font-size: var(--font-link);
    }
}

.skill-progress {
    position: relative;
    overflow: hidden;
    background: var(--color-white50);
    width: 100%;
    height: 25px;
    border-radius: var(--radius-base);
    transform: translateZ(0);
}

.skill-indicator {
    background-color: var(--color-text-primary);
    width: 100%;
    height: 100%;
    transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
