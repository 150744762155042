.CustomChatBot .rsc-container {
    backdrop-filter: blur(40px) saturate(1.3);
    -webkit-backdrop-filter: blur(40px) saturate(1.3);
    box-shadow: var(--shadow-lg);
    z-index: var(--zindex-chat-bot);
    transition: all 0.2s ease-in-out 0s;
}

.AlertDialogOverlay {
    background-color: var(--color-white30);
    backdrop-filter: blur(40px) saturate(1.3);
    -webkit-backdrop-filter: blur(40px) saturate(1.3);
    z-index: var(--zindex-alert);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.AlertButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 4px 0;
    font-size: var(--16px);
    border-radius: var(--16px);
    background: var(--color-white50);
}

.AlertButton:hover {
    box-shadow: 0 0 0 2px var(--color-text-primary);
}

.AlertDialogContent {
    background-color: var(--color-white30);
    backdrop-filter: blur(40px) saturate(1.3);
    -webkit-backdrop-filter: blur(40px) saturate(1.3);
    z-index: var(--zindex-alert);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    padding: var(--16px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out 0s;
}

@media (min-width: 768px) {
    .AlertDialogContent {
        padding: var(--24px);
        border-radius: var(--radius-base);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90vw;
        max-width: 600px;
        max-height: 85vh;
        box-shadow: var(--shadow-lg);
    }
}

.AlertDialogTitle {
    text-align: center;
    flex-grow: 0;
}

.AlertDialogDescription {
    flex-grow: 1;
    width: 80%;
    min-width: 200px;
    max-width: 300px;
}

@media (min-width: 768px) {
    .AlertDialogDescription {
        max-width: 500px;
        min-width: 300px;
    }
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.FormRoot {
    height: 100%;
    width: 100%;
    font: var(--bodyFont);
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .FormRoot {

    }
}

.FormField {
    display: grid;
    /*gap: calc(var(--16px) - 8px);*/
    /*margin-bottom: var(--16px);*/
    margin-bottom: calc(var(--16px) - 8px);
}

.FormField.Message {
    grid-template-rows: auto 1fr;
    flex-grow: 1;
}

.FormLabel {
    font-size: var(--font-h5);
    font-weight: 700;
    color: var(--color-text-primary);
    position: relative;
    display: flex;
}

.FormMessage {
    font-size: var(--16px);
    color: var(--color-text-contrast);
    opacity: 0.8;
}

.Input,
.Textarea {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-base);

    font-size: var(--16px);
    color: var(--color-text-secondary);
    background-color: var(--color-white30);
    box-shadow: var(--shadow-lg);
}
.Input:hover,
.Textarea:hover {
    box-shadow: 0 0 0 1px var(--color-white);
}
.Input:focus,
.Textarea:focus {
    box-shadow: 0 0 0 2px var(--color-white);
}
.Input::selection,
.Textarea::selection {
    background-color: var(--color-white70);
}

.Input {
    padding: 0 10px;
    height: 35px;
    line-height: 1;
    font-size: var(--16px);
    color: var(--color-text-secondary);
}

.Textarea {
    resize: none;
    padding: 0 10px;
    font-size: var(--16px);
    color: var(--color-text-secondary);
    margin-bottom: var(--14px);
}

@media (min-width: 768px) {
    .Input {
        font-size: var(--font-link);
    }

    .Textarea {
        font-size: var(--font-link);
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-base);
    padding: var(--14px) 4px;
    margin-bottom: var(--14px);
    font-size: var(--16px);
    color: var(--color-text-primary);
    line-height: 1;
    font-weight: 500;
    height: 35px;
    width: 100%;

    background-color: var(--color-white70);
    box-shadow: var(--shadow-lg);
}
.Button:hover {
    background-color: var(--color-white50);
}
.Button:focus {
    box-shadow: 0 0 0 2px var(--color-white);
}


.ToastViewport {
    --viewport-padding: 25px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: var(--viewport-padding);
    gap: 10px;
    width: 300px;
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: 2147483647;
    outline: none;
}

.ToastRoot {
    background-color: var(--color-white);
    border-radius: var(--16px);
    box-shadow: var(--shadow-lg);
    padding: 15px;
    display: grid;
    grid-template-areas: 'title action' 'description action';
    grid-template-columns: auto max-content;
    column-gap: 15px;
    align-items: center;
}
.ToastRoot[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state='closed'] {
    animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe='move'] {
    transform: translateX(0);
}
.ToastRoot[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe='end'] {
    animation: swipeOut 100ms ease-out;
}

@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(20%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes swipeOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(20%);
    }
}

.ToastTitle {
    grid-area: title;
    margin-bottom: 5px;
    font-weight: 700;
    color: var(--color-text-primary);
    font-size: var(--16px);
}

.ToastDescription {
    grid-area: description;
    margin: 0;
    color: var(--color-text-secondary);
    font-size: var(--14px);
    line-height: 1.3;
}

@media (max-width: 768px) {
    .ToastViewport {
        right: 50%;
        transform: translateX(50%);
        /*width: auto;*/
    }

    .ToastRoot {
        align-items: center;
    }

    @keyframes slideIn {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes swipeOut {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100%);
        }
    }

    .ToastRoot[data-state='open'] {
        animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    .ToastRoot[data-state='closed'] {
        animation: hide 100ms ease-in;
    }

    .ToastRoot[data-swipe='move'] {
        transform: translateY(0);
    }

    .ToastRoot[data-swipe='cancel'] {
        transform: translateY(0);
        transition: transform 200ms ease-out;
    }

    .ToastRoot[data-swipe='end'] {
        animation: swipeOut 100ms ease-out;
    }
}

