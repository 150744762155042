.about-container {
    display: flex;
    flex-direction: column;
}

.title-section {
    height: 100vh;
}

.bio {
    padding: 0 var(--64px) var(--64px);
}

.person {
    grid-column-start: 1;
    grid-column-end: 26;
    position: relative;
    align-content: center;
}

@media (min-width: 768px) {
    .person {
        grid-column-start: 1;
        grid-column-end: 10;
        margin-top: var(--spacing-xs);
    }
}

.person-content {
    position: relative;
}


.person-holder {
    max-height: 410px;
    overflow: hidden;
    border-radius: var(--21px);
    transition: all .8s var(--transition-bounce);
}

.person-holder:hover .person-img {
    transform: translateZ(0);
}

@media (min-width: 768px) {
    .person-holder {
        max-height: none;
        border-radius: var(--64px);
    }
}

@media (min-width: 544px) {
    .person-holder:hover {
        transform:translate3d(0,-3px,0);
        box-shadow: var(--shadow-lg);
        filter: saturate(1.2)
    }
}

.person-fig {
    border-radius: var(--21px);
    background: var(--color-opaque);
    position: relative;
    width: 100%;
    margin: 0;
}

@media (min-width: 768px) {
    .person-fig {
        border-radius: var(--64px);
    }
}

.person-img {
    object-fit: cover;
    width: 100%;
    opacity: 1;
    border-radius: var(--21px);
    transition: all 0.2s ease-in-out 0s;
    loading: lazy;
    display: block;
    cursor: pointer;
    max-width: inherit;
    position: relative;
}

@media (min-width: 768px) {
    .person-img {
        border-radius: var(--64px);
    }
}


.blurb {
    grid-column-start: 1;
    grid-column-end: 26;
    margin-top: var(--42px);
}

@media (min-width: 768px) {
    .blurb {
        grid-column-start: 11;
        grid-column-end: 26;
        margin-top: var(--24px);
    }
}

.intro {
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);
    font-size: var(--font-h2-display);
    line-height: 110%;
}

p {
    color: var(--color-text-secondary);
}

.abilities {
    padding: var(--spacing-s) 0 var(--spacing-l);
    grid-row-gap: var(--spacing-m);
    margin: var(--spacing-xs) auto var(--spacing-l);
    position: relative;
    background: var(--color-white30);
    backdrop-filter: blur(10px) saturate(1.3);
    -webkit-backdrop-filter: blur(10px) saturate(1.3);
}

@media (min-width: 768px) {
    .abilities {
        border-radius: var(--64px);
        padding: var(--spacing-l) 0 calc(var(--spacing-l) + var(--42px));
        margin: var(--spacing-xs) auto calc(var(--spacing-l) - 20px);
    }
}

.ability-1 {
    grid-column-start: 3;
    grid-column-end: 24;
    position: relative;
    z-index: var(--zindex-content);
}

@media (min-width: 768px) {
    .ability-1 {
        grid-column-start: 4;
        grid-column-end: 13;
    }
}

.ability-2 {
    grid-column-start: 3;
    grid-column-end: 24;
    position: relative;
    z-index: var(--zindex-content);
}

@media (min-width: 768px) {
    .ability-2 {
        grid-column-start: 14;
        grid-column-end: 23;
    }
}

.ability-3 {
    grid-column-start: 3;
    grid-column-end: 24;
    position: relative;
    z-index: var(--zindex-content);
}

@media (min-width: 768px) {
    .ability-3 {
        grid-column-start: 4;
        grid-column-end: 13;
    }
}

.ability-4 {
    grid-column-start: 3;
    grid-column-end: 24;
    position: relative;
    z-index: var(--zindex-content);
}

@media (min-width: 768px) {
    .ability-4 {
        grid-column-start: 14;
        grid-column-end: 23;
    }
}

@media (min-width: 544px) {
    .grid.info {
        padding: 0 var(--24px);
    }
}

.item.info {
    padding-bottom: max( 2.652rem , calc( 2.652rem + (84 - 42) * ((100vw - 375px) / (1600 - 375))) ) ;
    grid-column-start: 3;
    grid-column-end: 24;
}

@media (min-width: 768px) {
    .item.info {
        grid-column-start: 8;
        grid-column-end: 19;
    }
}

.collage {
    padding: 0 0 calc(var(--64px)* 2);
}

@media (min-width: 768px) {
    .collage {
        padding: 0 calc(var(--24px) + 6px) calc(var(--64px)* 2);
    }
}

.collage-content {
    display: grid;
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
}

@media (min-width: 768px) {
    .collage-content {
        grid-row-gap: var(--64px);
        grid-column-gap: var(--16px);
    }
}

@media (min-width: 992px) {
    .collage-content {
        grid-row-gap: calc(var(--64px)* 1.5);
    }
}

.collage-holder-1,
.collage-holder-2 {
    grid-column-start: 1;
    grid-column-end: 5;
    overflow: hidden;
    width: 100%;
    background: var(--color-white30);
    color: var(--color-text-bold);
    max-height: 20rem;
    position: relative;
    cursor: pointer;
    transition: all .5s var(--transition-bounce);
    text-decoration: none;
}

@media (min-width: 768px) {
    .collage-holder-1 {
        grid-column-start: 1;
        grid-column-end: 3;
        border-radius: var(--16px);
        max-height: 700px;
    }
    .collage-holder-2 {
        grid-column-start: 3;
        grid-column-end: 5;
        border-radius: var(--16px);
        max-height: 700px;
    }
}

.collage-img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    max-width: inherit;
    z-index: var(--zindex-content);
    display: flex;
    flex-direction: column;
}

.caption {
    position: absolute;
    bottom: var(--14px);
    left: var(--14px);
    display: flex;
    z-index: var(--zindex-content);
    background: var(--color-primary);
    border-radius: calc(var(--16px) / 2);
    padding: calc(var(--16px) / 2) var(--16px);
    color: var(--color-text-secondary);
    align-items: center;
    justify-items: center;
    transition: all 0.2s ease-in-out 0s;
    opacity: 0;
}

@media (min-width: 992px) {
    .caption {
        bottom: var(--18px);
        left: var(--18px);
    }
}

.collage-holder-1:hover .caption,
.collage-holder-2:hover .caption {
    opacity: 1;
}










