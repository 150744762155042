@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: acorn;
  src: url("./fonts/acorn.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: gt;
  src: url("./fonts/gt.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

:root {
  --titleFont: "acorn", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --bodyFont: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --ampFont: Baskerville, "Goudy Old Style", Palatino, "Book Antiqua", serif;
  --systemFont: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --monoFont: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", monospace;
  --color-white: hsla(0, 0%, 100%, 1);
  --color-white70: hsla(0, 0%, 100%, .7);
  --color-white50: hsla(0, 0%, 100%, .5);
  --color-white20: hsla(0, 0%, 100%, .2);
  --color-white30: hsla(0, 0%, 100%, .3);
  --color-backdrop: hsla(45, 17%, 95%, .8);
  --color-black: hsla(0, 0%, 0%, 1);
  --color-blue: hsla(204, 97%, 54%, 1);
  --color-lightblue: hsla(208, 90%, 40%, 1);
  --color-lightgrey: hsla(0, 0%, 97%, 1);
  --color-mint: hsla(172, 45%, 75%, 1);
  --color-lilac: hsla(227, 65%, 87%, 1);
  --color-rust: hsla(22, 62%, 76%, 1);
  --color-babyblue: hsla(206, 84%, 86%, 1);
  --color-pink: hsla(284, 61%, 74%, 1);
  --color-dark: hsla(0, 0%, 9%, 1);
  --color-hilite: hsla(172, 95%, 18%, 1);
  --color-hilite-off: hsla(172, 95%, 18%, 0);
  --color-white-flash: hsla(0, 0%, 100%, .5);
  --color-beige: hsla(36, 31%, 90%, 1);
  --color-green300: hsla(159, 42%, 88%, 1);
  --color-green400: hsla(160, 52%, 71%, 1);
  --color-green450: hsla(165, 60%, 41%, 1);
  --color-green500: hsla(172, 95%, 18%, 1);
  --color-green500-trans: hsla(172, 95%, 18%, 0.7);
  --color-green600: hsla(171, 15%, 35%, 1);
  --color-green700: hsla(171, 11%, 24%, 1);
  --color-green800: hsla(158, 23%, 18%, 1);
  --color-yellow500: hsla(36, 97%, 70%, 1);
  --color-yellow600: hsla(36, 74%, 25%, 1);
  --color-yellow700: hsla(36, 91%, 13%, 1);
  --spectrum-purple-200: hsla(261, 78.7%, 81.6%, 1);
  --spectrum-purple-600: hsla(267, 43%, 44.7%, 1);
  --spectrum-clay-400: #ded5d1;
  --spectrum-clay-500: #c2b4ac;
  --proto-bg-primary: #fff;
  --proto-bg-secondary: #ede8e6;
  --color-gradient: linear-gradient( to bottom, hsla(36, 31%, 90%, 0) 0%, hsla(36, 31%, 90%, .013) 9.7%, hsla(36, 31%, 90%, .049) 18.1%, hsla(36, 31%, 90%, .104) 25.5%, hsla(36, 31%, 90%, .175) 31.9%, hsla(36, 31%, 90%, .259) 37.7%, hsla(36, 31%, 90%, .352) 43%, hsla(36, 31%, 90%, .45) 47.9%, hsla(36, 31%, 90%, .55) 52.8%, hsla(36, 31%, 90%, .648) 57.7%, hsla(36, 31%, 90%, .741) 63%, hsla(36, 31%, 90%, .825) 68.7%, hsla(36, 31%, 90%, .896) 75%, hsla(36, 31%, 90%, .951) 82.2%, hsla(36, 31%, 90%, .987) 90.5%, hsl(36, 31%, 90%) 100% );
  --color-text-primary: var(--color-green500);
  --color-text-secondary: var(--color-green600);
  --color-text-tertiary: var(--color-beige);
  --color-text-quarterly: var(--color-green300);
  --color-text-hilite: var(--color-green400);
  --color-text-active: var(--color-white);
  --color-text-bold: var(--color-green700);
  --color-text-contrast: var(--color-green800);
  --color-text-ai: var(--spectrum-purple-600);
  --color-primary: var(--color-beige);
  --color-primary-accent: var(--color-green500);
  --color-primary-border: var(--color-white);
  --color-tertiary: var(--color-green300);
  --color-opaque: hsla(0, 0%, 100%, .5);
  --color-selector: var(--color-white);
  --color-light: var(--color-white);
  --color-light-border: rgba(0,0,0,.1);
  --color-white-border: rgba(0,0,0,.1);
  --color-light-hover: rgba(0,0,0,.05);
  --color-white-hover: rgba(0,0,0,.05);
  --color-dark: var(--color-black);
  --color-dark-border: rgba(255,255,255,.1);
  --canvas-opacity: .5;
  --font-h1: clamp(3.2rem, .5692rem + 8.238vw, 13.75rem);
  --font-h2: clamp(1.5rem, 1.0982rem + 1.7143vw, 2.8125rem);
  --font-h3: clamp(1.375rem, 1.1837rem + .8163vw, 2rem);
  --font-h4: clamp(1.375rem, 1.1837rem + .8163vw, 1.6rem);
  --font-h5: clamp(1rem, .9235rem + .3265vw, 1.25rem);
  --font-h6: clamp(1rem, .9617rem + .1633vw, 1.125rem);
  --font-body: clamp(1rem, .8852rem + .4898vw, 1.375rem);
  --font-link: clamp(.875rem, .7985rem + .3265vw, 1.125rem);
  --font-mini: clamp(.875rem, .8367rem + .1633vw, 1rem);
  --font-h2-display: clamp(1.875rem, 1.301rem + 2.449vw, 3.95rem);
  --14px: .875rem;
  --15px: .9375rem;
  --16px: 1rem;
  --17px: 1.0625rem;
  --18px: 1.125rem;
  --19px: 1.1875rem;
  --20px: 1.25rem;
  --21px: 1.3125rem;
  --24px: 1.5rem;
  --42px: 2.652rem;
  --64px: 4rem;
  --spacing-xxl: max( 130px, calc(130px + (260 - 130) * ((100vw - 375px) / (1600 - 375))) );
  --spacing-xl: max( var(--64px), calc(var(--64px) + (128 - 64) * ((100vw - 375px) / (1600 - 375))) );
  --spacing-l: max( var(--42px), calc(var(--42px) + (84 - 42) * ((100vw - 375px) / (1600 - 375))) );
  --spacing-m: max( var(--24px), calc(var(--24px) + (48 - 24) * ((100vw - 375px) / (1600 - 375))) );
  --spacing-s: max( var(--21px), calc(var(--21px) + (42 - 21) * ((100vw - 375px) / (1600 - 375))) );
  --spacing-xs: max( var(--14px), calc(var(--14px) + (28 - 14) * ((100vw - 375px) / (1600 - 375))) );
  --spacing-xxs: max( calc(var(--14px) / 2), calc((var(--14px) / 2) + (14 - 7) * ((100vw - 375px) / (1600 - 375))) );
  --max-width: 1600px;
  --transition-bounce: cubic-bezier(.175,.885,.32,1.275);
  --transition-ease: ease-in-out;
  --shadow-lg: 0px 50px 100px -20px rgba(0, 0, 0, .15);
  --zindex-base: 1;
  --zindex-2: 2;
  --zindex-content-lower-2: 40;
  --zindex-content-lower-1: 45;
  --zindex-content: 50;
  --zindex-top: 100;
  --zindex-nav: 1000;
  --zindex-nav-menu: 1010;
  --zindex-chat-bot: 1020;
  --zindex-alert: 1030;
  --zindex-recap: 1040;
  --zindex-modal: 2000;
  --radius-base: .75rem;
  --shadow-menu: 0px 10px 60px rgba(0, 0, 0, .1)
}

.light:root {
  --color-text-primary: var(--color-green500);
  --color-text-secondary: var(--color-green600);
  --color-text-tertiary: var(--color-beige);
  --color-text-quarterly: var(--color-green300);
  --color-text-hilite: var(--color-green400);
  --color-text-active: var(--color-white);
  --color-text-bold: var(--color-green700);
  --color-text-contrast: var(--color-green800);
  --color-text-ai: var(--spectrum-purple-600);
  --color-primary: var(--color-beige);
  --color-primary-accent: var(--color-green500);
  --color-primary-border: var(--color-white);
  --color-tertiary: var(--color-green300);
  --color-opaque: hsla(0, 0%, 100%, .5);
  --color-selector: var(--color-white);
  --color-light: var(--color-white);
  --color-light-border: rgba(0,0,0,.05);
  --color-white-border: rgba(0,0,0,.1);
  --color-light-hover: rgba(0,0,0,.05);
  --color-white-hover: rgba(0,0,0,.05);
  --color-dark: var(--color-black);
  --color-dark-border: rgba(255,255,255,.1)
}

.dark:root {
  --color-primary: var(--color-green800);
  --color-primary-accent: var(--color-green400);
  --color-gradient: linear-gradient( to bottom, hsla(158, 23%, 18%, 0) 0%, hsla(158, 23%, 18%, .013) 9.7%, hsla(158, 23%, 18%, .049) 18.1%, hsla(158, 23%, 18%, .104) 25.5%, hsla(158, 23%, 18%, .175) 31.9%, hsla(158, 23%, 18%, .259) 37.7%, hsla(158, 23%, 18%, .352) 43%, hsla(158, 23%, 18%, .45) 47.9%, hsla(158, 23%, 18%, .55) 52.8%, hsla(158, 23%, 18%, .648) 57.7%, hsla(158, 23%, 18%, .741) 63%, hsla(158, 23%, 18%, .825) 68.7%, hsla(158, 23%, 18%, .896) 75%, hsla(158, 23%, 18%, .951) 82.2%, hsla(158, 23%, 18%, .987) 90.5%, hsl(158, 23%, 18%) 100% );
  --color-white30: hsla(160, 52%, 71%, .05);
  --color-white50: hsla(160, 52%, 71%, .1);
  --color-backdrop: hsla(158, 23%, 18%, .7);
  --color-primary-border: hsla(160, 52%, 71%, .2);
  --color-text-primary: var(--color-green400);
  --color-text-secondary: var(--color-green300);
  --color-text-tertiary: var(--color-green800);
  --color-text-quarterly: var(--color-green800);
  --color-text-ai: var(--spectrum-purple-200);
  --color-dark: var(--color-white);
  --color-light: #2C2C2C;
  --color-lightgrey: var(--color-light);
  --color-opaque: hsla(160, 52%, 71%, .1);
  --color-light-border: hsla(160, 52%, 71%, .2);
  --color-white-border: hsla(0, 0%, 100%, .2);
  --color-light-hover: hsla(160, 52%, 71%, .1);
  --color-white-hover: hsla(0, 0%, 100%, .1);
  --color-blue: hsla(205, 90%, 48%, 1);
  --color-lightblue: hsla(205, 71%, 68%, 1);
  --color-tertiary: hsla(0, 0%, 100%, .1);
  --color-selector: var(--color-green800);
  --color-white-flash: hsla(0, 0%, 100%, .2);
  --spectrum-clay-400: rgba(255,255,255,.1);
  --spectrum-clay-500: #637871
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility
}

body,html {
  font-family: var(--bodyFont);
  font-size: var(--16px);
  color: var(--color-text-primary);
  background: var(--color-primary);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  transition: background .2s linear;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "liga" on;
  font-feature-settings: "liga" on;
  -moz-font-feature-settings: "liga" on
}

body.scroll-lock,html.scroll-lock,body.scroll-lock body,html.scroll-lock body,body.scroll-lock .article-holder,html.scroll-lock .article-holder {
  overflow: hidden!important
}

::selection {
  background: var(--color-primary-accent);
  color: var(--color-text-tertiary)
}

h1,h2,h3,h4,h5,h6 {
  font-family: var(--titleFont);
  font-weight: 700
}

h1 {
  text-align: center;
  font-size: var(--font-h1);
  line-height: 100%;
  letter-spacing: -2px
}

@media (min-width: 992px) {
  h1 {
    letter-spacing:-4px
  }
}

.h1-display {
  text-align: center;
  font-size: var(--font-h1);
  line-height: 100%;
  letter-spacing: -2px
}

@media (min-width: 992px) {
  .h1-display {
    letter-spacing:-4px
  }
}

h1 .holder {
  position: relative
}

h2 {
  font-size: var(--font-h2);
  line-height: 130%
}

.h2-display {
  font-size: var(--font-h2-display);
  line-height: 120%;
  letter-spacing: -.25px
}

@media (min-width: 768px) {
  .h2-display {
    line-height:140%;
    letter-spacing: -1px
  }
}

h3,.h3-display {
  font-size: var(--font-h3)
}

h6 {
  font-family: var(--bodyFont);
  font-weight: 700;
  font-size: var(--font-h6)
}

p {
  font-size: var(--font-body);
  line-height: 160%;
  color: var(--color-text-secondary)
}

p+p {
  margin-top: var(--spacing-xs)
}

h2+p {
  margin-top: var(--spacing-xs)
}

h3+p {
  margin-top: var(--spacing-xxs)
}

p+h2 {
  margin-top: var(--spacing-m)
}

p+h3 {
  margin-top: var(--spacing-m)
}

.footer {
  padding: var(--spacing-xl) 0 var(--spacing-m);
  position: relative;
  z-index: var(--zindex-content-lower-2);
}

.xs {
  margin-top: var(--spacing-xs)
}

.medium {
  margin-top: var(--spacing-m)
}

.extra {
  margin-top: var(--spacing-l)
}

.amp {
  font-family: var(--ampFont);
  font-style: italic;
  font-weight: 400
}

.content {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%
}

.grid {
  display: grid;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(25,1fr)
}

.grid-wrap {
  margin: 0;
}

@media (min-width: 768px) {
  .grid-wrap {
    margin: 0 var(--64px);
  }
}

.cards {
  padding: 0 calc(var(--24px) + 6px) calc(var(--64px) * 2)
}

.cards-content {
  display: grid;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(25,1fr);
  grid-row-gap: var(--64px)
}

@media (min-width: 992px) {
  .cards-content {
    grid-row-gap:calc(var(--64px) * 2.5)
  }
}


