.NavigationMenuRoot {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: var(--max-width);
    margin: var(--20px) auto 0;
    position: relative;
    z-index: var(--zindex-nav);
}

.NavigationMenuList {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: calc(var(--20px) - 10px);
    border-radius: 50px;
    transition: all .2s linear;
}

.scrolled {
    background-color: var(--color-backdrop);
    backdrop-filter: blur(20px) saturate(1.7);
    -webkit-backdrop-filter: blur(20px) saturate(1.7);
}

.NavigationMenuTrigger {
    display: flex;
    align-items: center;
}

.NavigationMenuLink {
    padding: calc(var(--16px) - 10px) calc(var(--16px) - 6px);
    margin: 0 3px;
    font-size: var(--font-link);
    border-radius: var(--24px);
    text-decoration: none;
    color: var(--color-text-secondary);
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
}

.NavigationMenuLink.active {
    background-color: var(--color-white50);
    border-radius: 24px;
}


.NavigationMenuIndicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 5px;
    width: 100%;
    bottom: 10%;
    overflow: hidden;
    z-index: var(--zindex-base);
    transition: width, transform 250ms ease;
    position: absolute;
}
.NavigationMenuIndicator[data-state='visible'] {
    animation: fadeIn 200ms ease;
}
.NavigationMenuIndicator[data-state='hidden'] {
    animation: fadeOut 200ms ease;
}

.Highlight {
    position: relative;
    top: 0;
    background-color: transparent;
    width: 100%;
    height: 5px;
    border-radius: var(--24px);
}

.Highlight.hovered {
    background-color: var(--color-white50);
}
